<template>
    <section>
        <b-form>
            <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div class="col-span-8">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 lg:col-span-12">
                            <input-required
                            v-model="value.nomeFarmacopeia"
                            label="Nome"
                            max="50"
                            disabled="true"
                            />
                        </div>
                        <div class="col-span-12 xl:col-span-12">
                            <input-default
                            v-model="value.descricaoFarmacopeia"
                            label="Observação"
                            max="50"
                            disabled="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
    </section>
</template>

<script>
import { BForm } from "bootstrap-vue";

import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";

export default {
    name: 'GeneralEnsaioInformation',
    components: {
        BForm,
        InputRequired,
        InputDefault,
    },
    props: {
        value: {
        type: Object,
        }
    }
}
</script>
  