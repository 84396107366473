<template>
  <section class="w-full">
    <header-register :showButtonView="false" @register="handleActivePageOperation">Farmacopeia</header-register>
    <main class="w-full">
      <ManageFarmacopeiaView/>
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageFarmacopeiaView from "@/components/groupProducts/farmacopeia/components/view/ManageFarmaView.vue";


export default {
  name: "EnsaiosView",
  components: {
    HeaderRegister,
    ManageFarmacopeiaView
},
};
</script>

