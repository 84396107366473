<template>
  <div>
    <section class="-mt-4">
      <GeneralEnsaioInformation class="mt-8" v-model="viewModel"/>
    </section>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
        <Button
          class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
          buttonText="Voltar"
          :onClick="redirectUrl"
        />
      </footer>
  </div>
</template>

<script>
import Button from "@core/components/commons/button/Button";
import GeneralEnsaioInformation from "./GeneralEnsaioInformation.vue";


export default {
  name: 'ManageFarmaView',
  components: {
    Button,
    GeneralEnsaioInformation,
},
  data() {
    return{
      viewModel: {
        nomeFarmacopeia: null,
        descricaoFarmacopeia: null,
      }
    }
  },
  created () {
    this.RecuperarFarmacopeia(this.$router.currentRoute.params.id);
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/groupProducts/farmacopeia");
    },
    RecuperarFarmacopeia (id) {
      this.$http({
        url: `/Farmacopeia/obter/${id}`,
        method: "Get"
      }).then((response) => {
        this.viewModel = {
            nomeFarmacopeia : response.data.nomeFarmacopeia,
            descricaoFarmacopeia: response.data.descricaoFarmacopeia,
        }
      })
    },
  }
}
</script>